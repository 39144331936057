import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { RootState } from '@/rootStateTypes';

const useSodiumAlertForStore = () => {
  const localizationInfo = useSelector(localizationSelectors.storeDetails);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const isShowSodiumAlertForStore = isYumEcomm && localizationInfo?.showSodiumAlerts;

  return {
    isShowSodiumAlertForStore
  };
};

export default useSodiumAlertForStore;
